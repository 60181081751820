import { Box, ButtonBase, Container, Divider, Grid, IconButton, Typography } from '@mui/material';
import NextImage from 'next/image';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import KakaoIcon from '@public/images/kakao.svg';
import InstargramIcon from '@public/images/instargram.svg';
import GithubIcon from '@public/images/github.svg';
import NaverCafeIcon from '@public/images/naver_cafe.svg';
import { MdOrUp } from '@ocodelib/ui-common';

export default function Footer() {
  return (
    <Box className="Footer-root" sx={{ backgroundColor: '#26282A' }}>
      <Container
        maxWidth={false}
        sx={{
          maxWidth: 1600,
        }}
      >
        <Box
          width="100%"
          sx={{
            py: 7,
            color: '#C6C8CA',
            display: 'flex',
            flexDirection: 'column',
            alignItems: { xs: 'center', md: 'stretch' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', md: 'space-between' },
            }}
          >
            <MdOrUp>
              <Box className="Footer-Contact Info">
                <Typography variant="subtitle1" color="#fff">
                  Contact Info
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, lineHeight: 2.5 }}>
                  (주)오랩 대표이사 오정주
                  <br />
                  서울특별시 금천구 가산디지털1로 171
                  <br />
                  사업자등록번호: 123-45-67890
                  <br />
                  통신 판매업신고: 1234-경기서울-1234
                </Typography>
              </Box>
            </MdOrUp>
            <Box className="Footer-Logo">
              <NextImage
                src="/_static/images/footer_ocodeLogo.png"
                alt=""
                width={178}
                height={48}
                priority
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: { xs: 'center', md: 'space-between' },
              alignItems: { xs: 'flex-start', md: 'flex-end' },
              mt: { xs: 13, md: 1 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                '& .MuiButtonBase-root > p': {
                  lineHeight: 1,
                },
                '& .MuiButtonBase-root:first-of-type': {
                  pr: 1,
                },
                '& .MuiButtonBase-root:last-of-type': {
                  pl: 1,
                },
                '& .MuiButtonBase-root:not(:last-of-type)': {
                  borderRight: '1px solid #C6C8CA',
                },
                '& .MuiButtonBase-root:not(:first-of-type, :last-of-type)': {
                  px: 1,
                },
              }}
            >
              <ButtonBase disableRipple sx={{ display: 'inline-block' }}>
                <Typography variant="body2">개인정보처리방침</Typography>
              </ButtonBase>
              <ButtonBase disableRipple>
                <Typography variant="body2">이용약관</Typography>
              </ButtonBase>
              <ButtonBase disableRipple>
                <Typography variant="body2">법적고지</Typography>
              </ButtonBase>
              <ButtonBase disableRipple>
                <Typography variant="body2">오픈소스 라이선스</Typography>
              </ButtonBase>
              <ButtonBase disableRipple>
                <Typography variant="body2">Contact Info</Typography>
              </ButtonBase>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { xs: 'flex-start', md: 'flex-end' },
                gap: 1,
              }}
            >
              <Box sx={{ display: 'flex', mt: { xs: 2, md: 0 } }}>
                <RoomOutlinedIcon />{' '}
                <Typography variant="body2" ml={1}>
                  대한민국, South Korea
                </Typography>
                <LanguageOutlinedIcon sx={{ ml: 3 }} />{' '}
                <Typography variant="body2" ml={1}>
                  한국어
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <IconButton>
                  <KakaoIcon />
                </IconButton>
                <IconButton>
                  <InstargramIcon />
                </IconButton>
                <IconButton>
                  <GithubIcon />
                </IconButton>
                <IconButton>
                  <NaverCafeIcon />
                </IconButton>
              </Box>

              <Typography variant="body2" color="#76787A">
                copyright(c)All rights reserved.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
